import React from "react"
import { Link } from "gatsby"

const AddedToCart = () => {
  return (
    <Link to="/cart">
      <div className="fixed w-max h-max right-6 bottom-6 border bg-terciary-100 border-green-200 p-4 rounded-sm z-50 shadow-inner">
        <p className="text-sm font-semibold">Dodano v Košarico!</p>
      </div>
    </Link>
  )
}

export default AddedToCart
